<template>
  <div>
    <validation-observer ref="simpleRules">
        <b-form-group id="input-group-pkgDesc" class="form-group">
          <validation-provider #default="{ errors }" name="package description" rules="required">

            <b-form-input
              id="pkgDescription"
              type="text"
              required
              placeholder="Enter Package Description"
              v-model="packageDescription"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group id="input-group-weight" class="form-group">
          <validation-provider #default="{ errors }" name="weight" rules="required">
            <b-form-input
              id="weight"
              type="number"
              required
              placeholder="Enter approximate weight in kg"
              v-model="weight"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group id="input-group-dimension" class="form-group">
          <validation-provider v-slot="{ errors }" name="dimensions" rules="required|dimensions">
            <b-form-input
            id="dimension"
            type="text"
            required
            placeholder="Enter approximate dimensions of the package in l*b*h format"
            v-model="packageDimension"
            :state="errors.length > 0 ? false : null"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    <PickUpMsg ref="pickUpMsg" />
  </div>
</template>
    
<script>
import { mapMutations, mapState, mapActions } from "vuex";
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BRow,
  BCol,
  BDropdown,
} from "bootstrap-vue";
import PickUpMsg from "./PickUpReceivedMsg.vue";
import PickUpAddress from "./PickUpAddress.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { extend } from 'vee-validate';

extend('dimensions', {
  validate: (value) => {
    const regex = /^\d+(\s?\*\s?\d+){2}$/;
    return regex.test(value);
  },
  message: 'The package dimensions must be in the format "l*b*h".',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    PickUpMsg,
    PickUpAddress,
  },
  computed: mapState({
  }),
  data() {
    return {
    packageDescription: "",
    weight: "",
    weightRule: "required", 
    packageDimension: "",
    dimensionRule: ["required", "dimensions"], 
    required,
  };
  },
  methods: {
    ...mapMutations({
      setPickupPackageDetails: "pickUpStore/SET_PICKUP_PACKAGE_DETAILS",
    }),
    ...mapActions({
      storeAllPickupFields: "pickUpStore/setAllPickupFields",
    }),
    showModal() {
      this.$refs.pickupPackageDetails.show();
    },
    closeModal() {},
    goBack() {
      this.$emit("openModal2");
    },
    checkValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.setPickupPackageDetails({
            pkgDesc: this.packageDescription,
            weight: this.weight,
            pkgDimensions: this.packageDimension,
            trackingNo: "--",
          });
          // this.storeAllPickupFields();
          resolve(true)
          } else {
            reject(new Error('Correct all value'))
          }
        })
      })
    },
  },
};
</script>
<style scoped>
#modal-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  color: #414141;
  font-weight: 500;
}
.btn-primary {
  background-color: #ffffff !important;
}
/* .buttons {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
} */
[dir] .btn-secondary {
    border-color: #ffffff !important;
    background-color: #4385f5 !important;
    box-shadow: none !important;
    margin-top: 10px;
}
</style>
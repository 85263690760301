<template>
  <div>
    <b-modal
      id="modal-1"
      ref="pickupReceivedMsg"
      title="Pickup Received"
      ok-only
      ok-title="Okay"
      centered
      @ok='showPickupItems'
    >
    <div>Thank you for requesting a pick-up. First pickup for a package is free, (Note: If there is more than one pickup, then we might charge you pickup fee, details will be in an email)</div>
    </b-modal>
  </div>
</template>
    
<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
  },
  methods: {
    showModal() {
      this.$refs.pickupReceivedMsg.show();
    },
    closeModal() {},
    showPickupItems() {
      this.$router.push({ name: 'pickup' });
    }
  },
};
</script>
    
<style scoped>
#modal-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  color: #414141;
  font-weight: 500;
}
.btn-primary {
  background-color: #4385f5 !important;
  align-items: center;
}
.input-group-text {
  color: red;
}
.input-group-text:focus-visible {
  border: none;
  border-radius: 0px;
  outline: none;
}
</style>
<template>
  <div>
    <validation-observer ref="simpleRules">
      <vue-google-autocomplete
              ref="address"
              id="map1"
              class="form-control"
              placeholder="Type your address here..."
              v-on:placechanged="getAddressData"
              country="in"
      >
      </vue-google-autocomplete>
        <br />
        <b-form-group id="input-group-addressLine-1" class="form-group">
          <validation-provider
            #default="{ errors }"
            name="address line 1"
            rules="required"
          >
            <b-form-input
              id="addressLine1"
              type="text"
              required
              placeholder="Address Line 1"
              :state="errors.length > 0 ? false : null"
              v-model="addressLine1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group id="input-group-addressLine-2" class="form-group">
          <validation-provider
            #default="{ errors }"
            name="address line 2"
            rules="required"
          >
            <b-form-input
              id="addressLine2"
              type="text"
              required
              placeholder="Address Line 2"
              :state="errors.length > 0 ? false : null"
              v-model="addressLine2"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-row>
          <b-col md="6">
            <b-form-group id="input-group-pin" class="form-group">
              <validation-provider
                #default="{ errors }"
                name="pin"
                rules="required"
              >
                <b-form-input
                  id="pin"
                  type="number"
                  required
                  placeholder="PinCode"
                  :state="errors.length > 0 ? false : null"
                  v-model="pinCode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group id="input-group-city" class="form-group">
              <validation-provider
                #default="{ errors }"
                name="city"
                rules="required"
              >
                <b-form-input
                  id="city"
                  type="text"
                  required
                  placeholder="City"
                  v-model="city"
                  :state="errors.length > 0 ? false : null"
                />
               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group id="input-group-state" class="form-group">
              <validation-provider
                #default="{ errors }"
                name="state"
                rules="required"
              >
                <b-form-input
                  id="state"
                  type="text"
                  required
                  placeholder="State"
                  v-model="state"
                  :state="errors.length > 0 ? false : null"
                />
                
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group id="input-group-country" class="form-group">
              <validation-provider
                #default="{ errors }"
                name="country"
                rules="required"
              >
                <b-form-input
                  id="country"
                  type="text"
                  required
                  placeholder="Country"
                  v-model="country"
                  :state="errors.length > 0 ? false : null"
                />
               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { BFormGroup, BFormInput, BInputGroup, BRow, BCol } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BRow,
    BCol,
    vueGoogleAutocomplete
  },
  data() {
    return {
      addressLine1: "",
      addressLine2: "",
      pinCode: "",
      city: "",
      state: "",
      country: "",
      required,
      address: ''
    };
  },
  computed: mapState({
    getAddressLine1FromStore: (state) => {
      return state.pickUpStore.addressLine1;
    },
    getAddressLine2FromStore: (state) => {
      return state.pickUpStore.addressLine2;
    },
    getPinFromStore: (state) => {
      return state.pickUpStore.pinCode;
    },
    getCityFromStore: (state) => {
      return state.pickUpStore.city;
    },
    getStateFromStore: (state) => {
      return state.pickUpStore.st;
    },
    getCountryFromStore: (state) => {
      return state.pickUpStore.country;
    },
  }),
  methods: {
    ...mapMutations({
      setPickupAddressDetails: "pickUpStore/SET_PICKUP_ADDRESS_DETAILS",
    }),
    ...mapActions({}),
    showModal() {
      this.$refs.pickupAddress.show();
    },
    closeModal() {},
    goBack() {
      this.$emit('openModal')
    },
    getAddressData: function (addressData, placeResultData, id) {
       this.address = addressData;
       this.addressLine1 = addressData.street_number;
       this.addressLine2 = addressData.route;
       this.state = addressData.administrative_area_level_1;
       this.city = addressData.locality;
       this.pinCode = addressData.postal_code;
       this.country = addressData.country;
    },
    checkValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.setPickupAddressDetails({
            addLine1: this.addressLine1,
            addLine2: this.addressLine2,
            pin: this.pinCode,
            city: this.city,
            state: this.state,
            country: this.country,
          });
          resolve(true)
          } else {
            reject(new Error('Correct all value'))
          }
        })
      })
    },
  },
  mounted() {
  }
};
</script>
    
<style scoped>
#modal-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  color: #414141;
  font-weight: 500;
}
.btn-primary {
  background-color: #4385f5 !important;
}
.input-group-text {
  color: red;
}
.input-group:focus {
  outline: none;
}
</style>
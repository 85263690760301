  <template>
    <div class="form_wizard_root">
      <form-wizard 
      v-if="$mq === 'largeDevices'"
      ref="wizard"
      color="#4385F5" 
      :title="null" 
      :subtitle="null"
      shape="tab" 
      error-color="#ff4949">

        <tab-content title="Pick Up Person's Information"
        icon="ti ti-user"
        :before-change="() => validate('personsInfo')"> 
          <PickupPersonInfo ref="personsInfo"/>
        </tab-content>
        <tab-content title="Pick Up Address Details"
        icon="ti ti-home"
        :before-change="() => validate('addressInfo')">
          <PickupAddressDetails ref="addressInfo"/>
        </tab-content>
        <tab-content title="Pick Up Package Details" icon="ti ti-package"
        :before-change="() => validate('packageInfo')">
          <PickupPackageDetails ref="packageInfo"/>
        </tab-content>
        <tab-content title="Shipping Address" icon="ti ti-world"
        :before-change="() => validate('shippingAddress')">
          <ShippingAddress ref="shippingAddress"/>
        </tab-content>
        <template
          slot="footer"
          slot-scope="{ activeTabIndex, isLastStep, nextTab, prevTab }"
        >
          <div class="wizard-footer-left">
            <button
              v-if="activeTabIndex == 0"
              @click="goToPickupItems"
              type="button"
              class="btn goback"
            >
              Go Back
            </button>
            <button
              v-if="activeTabIndex > 0"
              @click="prevTab"
              type="button"
              class="btn goback"
            >
              Go Back
            </button>
          </div>
          <div class="wizard-footer-right">
            <button
              v-if="!isLastStep"
              @click="nextTab"
              type="button"
              class="btn wizard-footer-right continue"
            >
              Continue
            </button>

            <button
              v-else
              type="button"
              class="btn finish" 
              @click="showPickupFinalModal"
            >
              Finish
            </button>
          </div>
        </template>
      </form-wizard>

      <div v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
        <div v-if="showPersonDetails" class="h-text">Pick Up Person's Information
          <PickupPersonInfo ref="personsInfo" class="p__"/>
        </div>
        <div v-if="showPersonDetails" class="h-text">Pick Up Address Details
          <PickupAddressDetails ref="addressInfo" class="p__"/>
        </div>
        <div v-if="showPackageDetails" class="h-text">Pick Up Package Details
          <PickupPackageDetails ref="packageInfo" class="p__"/>
        </div>
        <div v-if="showPackageDetails" class="h-text">
          Shipping Address
          <ShippingAddress ref="shippingAddress" class="p__" />
        </div>

        <div class="buttons">
          <b-button class="goBack" @click="goToPickupItems" v-if="showPersonDetails">Go Back</b-button>
          <b-button class="goBack" @click="goBackToPreviousScreen" v-else>Go Back</b-button>
          <b-button class="continue" @click="showPackageWizard" v-if="showPersonDetails">Continue</b-button>
          <b-button class="continue" @click="showPickupFinalModal" v-else>Finish</b-button>
        </div>
      </div>
      <PickUpReceivedMsg ref="pickupReceivedMsg"/>
    </div>
  </template>

  <script>
  import { FormWizard, TabContent } from "vue-form-wizard";
  import { mapMutations } from "vuex";
  import { BButton } from "bootstrap-vue";
  import PickupPersonInfo from "./PickupPersonsInfo.vue";
  import PickupAddressDetails from "./PickUpAddress.vue";
  import PickupPackageDetails from "./PickUpPackageDetails.vue";
  import ShippingAddress from "./PickupAddressSelect.vue";
  import 'vue-form-wizard/dist/vue-form-wizard.min.css';
  import PickUpReceivedMsg from './PickUpReceivedMsg.vue';
  import Vue from 'vue'
  import VueMq from 'vue-mq'

  Vue.use(VueMq, {
    breakpoints: {
      smallDevices: 600,
      mediumDevices: 1200,
      largeDevices: Infinity
    }
  })

  export default {
    components: {
      FormWizard,
      TabContent,
      BButton,
      PickupPersonInfo,
      PickupAddressDetails,
      PickupPackageDetails,
      PickUpReceivedMsg,
      ShippingAddress
    },
    data() {
      return {
        showPersonDetails: true,
        showPackageDetails: false
      }
    },
    methods: {
      ...mapMutations({
        setSidebarRoute: "routesStore/SET_SIDEBAR",
        setMyOrdersNavbar: "routesStore/ SET_MY_ORDERS_NAVBAR",
        setNavbarRoute: "routesStore/SET_NAVBAR",
      }),
      validate(ref) {
        return this.$refs[ref].checkValidation();
      },
      async showPickupFinalModal() {
          await this.validate('shippingAddress')
          this.$refs.pickupReceivedMsg.showModal();
      },
      goToPickupItems() {
        this.$router.push({ name: 'pickup' })
      },
      async showPackageWizard() {
        const valid1 = await this.$refs.personsInfo.checkValidation()
        const valid2 = await this.$refs.addressInfo.checkValidation()
        if(valid1 && valid2) {
          this.showPersonDetails = !this.showPersonDetails
          this.showPackageDetails = !this.showPackageDetails
        }
      },
      goBackToPreviousScreen() {
        this.showPersonDetails = !this.showPersonDetails
        this.showPackageDetails = !this.showPackageDetails
      }
    },
    mounted() {
      this.setSidebarRoute("pickup");
      this.setMyOrdersNavbar("");
      this.setNavbarRoute("dashboardLanding");
    },
  };
  </script>

  <style>
  .form_wizard_root {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 4rem;
    background: #ffffff;
    /* width: 100%; */
  }
  .vue-form-wizard .wizard-tab-content {
      width: 65rem;
  }
  .continue {
      border-color: none !important;
      box-shadow: none !important;
      background-color: #4385F5 !important;
      color: white !important;
  }
  .goback {
      border-color: none !important;
      box-shadow: none !important;
      background-color: #D9E7FD !important;
      color: #4385F5 !important;
  }
  .finish {
      border-color: none !important;
      box-shadow: none !important;
      background-color: #4385F5 !important;
      color: white !important;
  }
  @media screen and (max-width: 1200px) {
    .form_wizard_root {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    padding: 1rem;
    margin: 0rem;
    background: #ffffff;
    width: 100%;
    height: 100vh;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
  }
  .goBack {
    background: #D9E7FD !important;
    color: #4385F5 !important;
    border: none !important;
    box-shadow: none !important;
  }
  .continue {
    background: #4385F5 !important;
    color: #ffffff !important;
    border: none !important;
    box-shadow: none !important;
  }
  .h-text {
    font-size: 5vw;
    margin-bottom: 0.5rem;
  }
  .p__ {
    margin-top: 0.5rem;
  }
  }

  @media screen and (min-width: 1250px) and (max-width: 1400px) {
    .vue-form-wizard .wizard-tab-content {
      width: 55rem;
    }
  }
  </style>
